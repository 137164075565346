import React, { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import { Form, Formik } from 'formik';
import { useTranslation } from 'next-i18next';

import type { AutocompleteInputChangeReason } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import type { BoxProps } from '@mui/material/Box';
import Box from '@mui/material/Box';

import Icon from 'src/components/ui/Icon';
import Autocomplete from 'src/components/ui/Autocomplete';
import CityPicker from 'src/components/ui/CityPicker';
import Button from 'src/components/ui/Button';
import Select from 'src/components/ui/Select';
import IconButton from 'src/components/ui/IconButton';
import MultiEntityAutocompleteOption from 'src/components/ui/MultiEntityAutocompleteOption';

import type {
    User,
    Company,
    PagesJaunesProSearchResult,
    CommunitySearchResponse,
    SearchCommunityQueryVariables,
} from 'src/types/__generated__/graphql';
import { useSearchCommunityQuery } from 'src/hooks/__generated__/queries';
import useEvents from 'src/hooks/useEvents';
import useUser from 'src/hooks/useUser';
import type { ActivityItem } from 'src/hooks/useActivities';
import useActivities from 'src/hooks/useActivities';
import type { CommunityOrderByKey } from 'src/hooks/useCommunityFilters';
import useCommunityFilters, {
    defaultCityRadiusKm,
    defaultCommunityEntityTypes,
    defaultCommunityOrderBy,
} from 'src/hooks/useCommunityFilters';
import useAppRouter from 'src/hooks/useAppRouter';
import autocompleteInputStyles from 'src/constants/autocompleteInputStyles';

import { routePathCompany, routePathCompanyPagesJaunes, routePathUser } from 'src/constants/router';

import { styledComponent } from 'src/utils/styled';
import { groupBy } from 'src/utils/groupBy';
import { removeAccents } from 'src/utils/removeAccents';
import { colours } from 'src/constants/colours';
import { breakpoints } from 'src/constants/breakpoints';
import { AnalyticsEvent, communityOrderByKeysMappedToAnalyticsEvents } from 'src/utils/analytics';
import useDynamicLogEvents from 'src/hooks/useDynamicLogEvents';

export type SearchCommunityResult = NonNullable<ArrayElement<CommunitySearchResponse['results']>>;
export type CommunityItem = {
    searchCommunityResult?: SearchCommunityResult;
    value: string;
    label: string;
    category: string;
    categoryLabelKey?: string;
    labelKey?: string;
};

const StyledAutocomplete = styledComponent(Autocomplete)`
    ${autocompleteInputStyles}
    flex: 1;
    .MuiInputLabel-formControl {
        transform: translate(0.75rem, 0.78rem) scale(1);
        margin-left: 0.8rem;
    }
    .MuiInputBase-root > span {
        width: 3rem !important;
    }
    .MuiInputBase-input {
        margin-left: -0.4rem;
    }
    span.start-loading {
        margin-left: 1rem;
        margin-right: 0.74rem;
    }

    @media (max-width: ${breakpoints.tabletLandscape - 1}px) {
        border-radius: 6rem;
        padding: 0.3rem;
        background-color: ${colours.primary.O08};
        overflow: hidden;
    }
`;

const InputContainer = styledComponent(Box)`
    display: flex;
    overflow: hidden;
    border-radius: 6rem;
    background-color: ${colours.primary.O08};
    height: 3.7rem;
    padding: 0.3rem;

    .MuiTextField-root {
        margin: 0;

        .MuiInputBase-root {
            margin-top: -0.1rem;
            height: 100%;
            border: none;
            border-radius: 0;
        }

    }
    label.MuiInputLabel-root {
        transform: translate(0.75rem, 0.78rem) scale(1);
    }

    @media (max-width: ${breakpoints.tabletLandscape - 1}px) {
        flex-direction: column;
        height: unset;
        background-color: unset;
        gap: 0.3rem;
        border-radius: unset;
        overflow: unset;
        max-width: 38rem;
        margin: auto;
    }
`;

const CityAndButtonContainer = styledComponent(Box)`
    flex: 1;
    display: flex;
    .MuiAutocomplete-root {
        flex: 1;
        .MuiInputBase-root > span {
            width: 3rem !important;
        }
        .MuiInputBase-input {
            padding-top: 1.3rem !important;
            margin-left: -0.7rem;
        }

        .MuiInputLabel-root {
            transform: translate(0.75rem, 0.78rem) scale(1);
            margin-left: 0.8rem;

            &:not(.Mui-focused) {
                color: ${colours.primary.O60};
            }

            &.Mui-focused {
                transform: translate(0.5rem, 0.3rem) scale(0.75) !important;
            }

            &.MuiFormLabel-filled {
                transform: translate(0.5rem, 0.3rem) scale(0.75) !important;
            }
        }
    }

    @media (max-width: ${breakpoints.tabletLandscape - 1}px) {
        border-radius: 6rem;
        padding: 0.3rem;
        background-color: ${colours.primary.O08};
        overflow: hidden;
    }
`;

const Divider = styledComponent(Box)`
    height: 100%;
    width: 0.0625rem;
    display: flex;
    align-items: center;

    .MuiBox-root {
        height: 75%;
        width: 100%;
        background-color: ${colours.grey.G300};
    }

    @media (max-width: ${breakpoints.tabletLandscape - 1}px) {
        visibility: collapse;
    }
`;

const Action = styledComponent(Box)`
    display: flex;
    margin: 0 0.3rem;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    > :not(:first-of-type) {
        margin-left: 0.5rem;
    }
    overflow-x: scroll;
    /* Hide scrollbar */
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    @media (max-width: ${breakpoints.tabletPortrait - 1}px) {
        justify-content: unset;
    }
`;

const StyledSelect = styledComponent(Select)<{ $minWidth?: string }>`
    height: 2rem;
    width: fit-content;
    ${({ $minWidth }) => $minWidth && `min-width: ${$minWidth};`}
    @media (max-width: ${breakpoints.tabletPortrait - 1}px) {
        min-width: fit-content;
    }
    .MuiInputBase-input {
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
    }
    .MuiInputBase-adornedStart {
        padding-left: 0.5rem;
        padding-right: 0.1rem;
    }
    .MuiSelect-select:focus {
        background-color: transparent;
    }
`;

export type CustomQueryVariablesCommunitySearch = Omit<
    SearchCommunityQueryVariables,
    'cityRadiusKm' | 'orderBy' | 'search' | 'activityId' | 'categoryId' | 'cityId' | 'communityEntityTypes'
> & {
    search?: string | null;
    activityId?: string | null;
    categoryId?: string | null;
    cityId?: string | null;
    cityName?: string | null;
    cityRadiusKm?: string | number | null;
    activityType?: 'user' | 'company' | null;
    activityCategoryKey?: string; // activityId or categoryId with the prefix, needed to display the good value in the input
    orderBy?: Record<string, 'ASC' | 'DESC'>;
    orderByKey?: CommunityOrderByKey;
    communityEntityTypes?: string; // when the string is parsed it becomes CommunityEntityType[]
};

interface Props {
    label: string;
    searchProfiles?: boolean;
    showSeeMoreButton?: boolean;
    defaultValues?: CustomQueryVariablesCommunitySearch;
    onSubmit: (values: CustomQueryVariablesCommunitySearch) => void;
    displayExtraFilters?: boolean;
    replaceQueryParams?: (params: Partial<CustomQueryVariablesCommunitySearch>) => Promise<void>;
    autoSubmitOnChange?: boolean;
}

const suggestedProfilesLimit = 10;

const logEventOrderByLabels: Record<CommunityOrderByKey, string> = {
    name: 'profile',
    distanceCity: 'distance',
    recommendation: 'recommendation',
    averageRating: 'note',
    mutualContacts: 'relation',
};

const CommunitySearchForm: React.FC<Props> = ({
    label,
    onSubmit,
    defaultValues,
    searchProfiles = true,
    displayExtraFilters,
    replaceQueryParams,
    showSeeMoreButton,
    autoSubmitOnChange,
}) => {
    const { t } = useTranslation(['community', 'common', 'company']);
    const router = useAppRouter();
    const { logEventDynamic } = useDynamicLogEvents();
    const [items, setItems] = useState<CommunityItem[]>([]);
    const [lastEnabledCityRadiusKm, setLastEnabledCityRadiusKm] = useState<
        CustomQueryVariablesCommunitySearch['cityRadiusKm']
    >(defaultValues?.cityRadiusKm ?? (defaultValues?.cityId ? defaultCityRadiusKm : 'unlimited'));
    const [lastEnabledOrderByKey, setLastEnabledOrderByKey] = useState<
        CustomQueryVariablesCommunitySearch['orderByKey']
    >(defaultValues?.orderByKey ?? 'recommendation');
    const [allActivitiesItems, setAllActivitiesItems] = useState<ActivityItem[]>([]);
    const [activitiesItems, setActivitiesItems] = useState<ActivityItem[]>([]);
    const [search, setSearch] = useState('');
    const [debouncedQuery, setDebouncedQuery] = useState('');
    const currentUser = useUser();
    const { trackSearchResultImpressionPagesJaunesMerchant } = useEvents();
    const getActivities = useActivities();
    const { getCommunityRadiusItems, getCommunityEntityTypesItems, getCommunityOrderByItems } = useCommunityFilters();

    const [currentCityId, setCurrentCityId] = useState<string | undefined>(defaultValues?.cityId ?? undefined);
    const searchButtonResponsive = useMediaQuery(`(max-width: ${breakpoints.tabletPortrait - 1}px)`);

    useDebounce(() => setDebouncedQuery(search), 500, [search]);

    const { data, loading } = useSearchCommunityQuery({
        variables: {
            search: debouncedQuery.length > 0 ? debouncedQuery : null,
            limit: suggestedProfilesLimit,
            cityId: currentCityId,
            cityRadiusKm: currentCityId ? defaultCityRadiusKm : undefined,
            orderBy: defaultCommunityOrderBy,
        },
        skip: !search.length || !debouncedQuery.length || !searchProfiles,
    });

    useEffect(() => {
        setCurrentCityId(defaultValues?.cityId ?? undefined);
    }, [defaultValues?.cityId]);

    useEffect(() => {
        const fetchActivitiesByCategories = async () => {
            const activities = await getActivities({
                forCommunity: true,
                displayExtraCompanyActivities: true,
            });
            setAllActivitiesItems(activities);
        };
        fetchActivitiesByCategories().catch(console.error);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const matchingItems = allActivitiesItems.filter((activity) =>
            removeAccents(activity.label.toLocaleLowerCase()).includes(removeAccents(search.toLocaleLowerCase())),
        );
        const groupedByCategory = groupBy(matchingItems, 'category');
        const filtered: ActivityItem[] = [];
        // Display only 10 items of each category
        Object.values(groupedByCategory).forEach((activities) => {
            filtered.push(...activities.slice(0, 10));
        });

        // If the selected activity/category is not in the Autocomplete items,
        // the Autocomplete will not be able to select it, so we add it in the items list
        if (defaultValues?.activityCategoryKey) {
            const [_, id] = String(defaultValues.activityCategoryKey).split(':');
            if (!filtered.some((a) => a.id === id)) {
                const selectedItem = allActivitiesItems.find((i) => i.id === id);
                if (selectedItem) {
                    filtered.push(selectedItem);
                }
            }
        }

        setActivitiesItems(filtered);
    }, [search, allActivitiesItems, defaultValues?.activityCategoryKey]);

    let communityResultItems: CommunityItem[] = [];
    if (data?.searchCommunity?.results) {
        communityResultItems = data.searchCommunity.results.map((communityResultItem) => {
            let id: string;
            let itemLabel: string;

            if (communityResultItem.__typename === 'User') {
                const user = communityResultItem as User;
                id = user.id;
                itemLabel = user.fullName ?? '';
            } else if (communityResultItem.__typename === 'Company') {
                const company = communityResultItem as Company;
                id = company.id;
                itemLabel = company.name;
            } else {
                const pagesJaunesPro = communityResultItem as PagesJaunesProSearchResult;
                id = pagesJaunesPro.merchantId;
                itemLabel = pagesJaunesPro.merchantName;
            }

            return {
                value: `${communityResultItem.__typename}:${id}${
                    communityResultItem.__typename === 'PagesJaunesProSearchResult' &&
                    communityResultItem.woopenCompanyId
                        ? `:${communityResultItem.woopenCompanyId}`
                        : ''
                }`,
                searchCommunityResult: communityResultItem,
                label: itemLabel,
                category: t('community:suggestionsHeader'),
            } as unknown as CommunityItem;
        });
    }

    useEffect(() => {
        setItems([...(activitiesItems as CommunityItem[]), ...communityResultItems]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activitiesItems]);

    useEffect(() => {
        if (!loading) setItems([...(activitiesItems as CommunityItem[]), ...communityResultItems]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, loading]);

    const radiusItems = getCommunityRadiusItems();
    const communityEntityTypesItems = getCommunityEntityTypesItems();
    const communityOrderByItems = getCommunityOrderByItems();

    const initialValues: CustomQueryVariablesCommunitySearch = defaultValues ?? {
        search: '',
        cityId: undefined,
        cityName: undefined,
        cityRadiusKm: 'unlimited',
        communityEntityTypes: defaultCommunityEntityTypes,
        orderBy: defaultCommunityOrderBy,
    };

    const getOrderByItemsDependingValues = (values: CustomQueryVariablesCommunitySearch) => {
        let orderByItems = communityOrderByItems;
        if (!values.cityId) {
            orderByItems = orderByItems.map((item) => ({
                ...item,
                disabled: item.value === 'distanceCity' || item.disabled,
            }));
        }
        if (
            values.communityEntityTypes &&
            [String(['pro', 'nonPro']), String(['nonPro'])].includes(values.communityEntityTypes)
        ) {
            orderByItems = orderByItems.map((item) => ({
                ...item,
                disabled:
                    (item.value && ['recommendation', 'averageRating'].includes(String(item.value))) || item.disabled,
            }));
        }

        return orderByItems;
    };

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validateOnMount enableReinitialize>
            {({ setFieldValue, values, submitForm }) => (
                <Form data-target-id="community-search-form">
                    <InputContainer>
                        <StyledAutocomplete
                            label={label}
                            name="activityCategoryKey"
                            inputName="search"
                            items={items}
                            loading={loading}
                            loadingPosition="start"
                            filterOptions={(options) => {
                                const results = [...options];
                                if (showSeeMoreButton && (data?.searchCommunity?.total ?? 0) > suggestedProfilesLimit) {
                                    results.push('SeeAllButton');
                                }
                                return results;
                            }}
                            groupBy={(option: unknown) => (option as ActivityItem).category}
                            startIcon={<Icon name="Search" />}
                            renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: unknown) => {
                                const item = option as CommunityItem;
                                if (option === 'SeeAllButton') {
                                    return (
                                        <Box display="flex" justifyContent="center" marginTop="0.5rem">
                                            <Button type="button" variant="outlined" onClick={submitForm}>
                                                {t('common:seeMore')}
                                            </Button>
                                        </Box>
                                    );
                                } else if (typeof item.searchCommunityResult === 'object') {
                                    return (
                                        <MultiEntityAutocompleteOption
                                            renderOptionProps={props}
                                            option={item.searchCommunityResult}
                                            handleVisibilityChangedPJPro={(isVisible: boolean) => {
                                                if (isVisible) {
                                                    trackSearchResultImpressionPagesJaunesMerchant({
                                                        pagesJaunesMerchantId: (
                                                            item.searchCommunityResult as unknown as PagesJaunesProSearchResult
                                                        ).merchantId,
                                                        userId: currentUser?.id,
                                                    });
                                                }
                                            }}
                                        />
                                    );
                                }
                                // Uncomment this and delete 2nd return when we'll want to display the matching characters in bold
                                // const parts = getHighlightParts(item.label, inputValue, { ignoreCase: true });
                                // return (
                                //     <Box
                                //         key={item.value}
                                //         {...(props as BoxProps)}
                                //         my="-0.25rem"
                                //         ml="-0.25rem"
                                //         data-target="autocomplete-option"
                                //         data-target-id={`autocomplete-option-${item.value}`}
                                //     >
                                //         {parts.map((part, index) => (
                                //             <span
                                //                 key={index}
                                //                 style={{
                                //                     whiteSpace: 'pre',
                                //                     fontWeight: part.highlight ? 'bold' : 'normal',
                                //                 }}
                                //             >
                                //                 {part.text}
                                //             </span>
                                //         ))}
                                //     </Box>
                                // );
                                return (
                                    <Box
                                        key={item.value}
                                        {...(props as BoxProps)}
                                        my="-0.25rem"
                                        ml="-0.25rem"
                                        data-target="autocomplete-option"
                                        data-target-id={`autocomplete-option-${item.value}`}
                                    >
                                        {item.label}
                                    </Box>
                                );
                            }}
                            InputProps={{
                                inputProps: {
                                    'data-target-id': 'community-search-autocomplete',
                                },
                            }}
                            onChange={async (value) => {
                                if (value) {
                                    const [type, id, woopenCompanyIdCorrespondingToPJPro] = String(value).split(':');
                                    if (type === 'User') {
                                        await router.push(`${routePathUser}/${id}`);
                                    }
                                    if (type === 'Company') {
                                        await router.push(`${routePathCompany}/${id}`);
                                    }
                                    if (type === 'PagesJaunesProSearchResult') {
                                        // if the pages jaunes pro page was used to create a woopen company, we redirect directly to this company
                                        await router.push(
                                            woopenCompanyIdCorrespondingToPJPro
                                                ? `${routePathCompany}/${woopenCompanyIdCorrespondingToPJPro}`
                                                : `${routePathCompanyPagesJaunes}/${id}`,
                                        );
                                    }
                                    if (
                                        ['Activity', 'Category'].includes(type) &&
                                        values.communityEntityTypes &&
                                        [String(['pro', 'nonPro']), String(['nonPro'])].includes(
                                            values.communityEntityTypes,
                                        )
                                    ) {
                                        // means if we want to search by activity/category but the entity types filter is on nonPro or allProfiles
                                        setFieldValue('communityEntityTypes', String(['company', 'pagesJaunesPro']));
                                    }
                                    if (autoSubmitOnChange) {
                                        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- no need to wait
                                        submitForm();
                                    }
                                }
                            }}
                            onBlur={
                                autoSubmitOnChange
                                    ? (_value) => {
                                          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- no need to wait
                                          submitForm();
                                      }
                                    : undefined
                            }
                            onSearchInputChange={(value: string, reason: AutocompleteInputChangeReason) => {
                                setSearch(value);
                                // Submit form when click on clear button
                                if (autoSubmitOnChange && reason === 'clear') {
                                    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- no need to wait
                                    submitForm();
                                }
                            }}
                            isLabelTranlated
                            allowFreeInput
                        />
                        <Divider>
                            <Box />
                        </Divider>
                        <CityAndButtonContainer>
                            <CityPicker
                                key={`${initialValues.cityId}-city-picker-key`}
                                iconColor={colours.primary.main}
                                name="cityId"
                                label={t('common:location')}
                                InputProps={{
                                    inputProps: {
                                        'data-target-id': 'community-search-location',
                                    },
                                }}
                                onChange={(item) => {
                                    setFieldValue('cityName', item?.name);
                                    setCurrentCityId(item?.id);
                                    if (!item) {
                                        if (values.orderByKey === 'distanceCity') {
                                            const newOrderByKey = [
                                                String(['pro', 'nonPro']),
                                                String(['nonPro']),
                                            ].includes(String(values.communityEntityTypes))
                                                ? 'mutualContacts'
                                                : 'recommendation';
                                            setFieldValue('orderByKey', newOrderByKey);
                                        }
                                        setFieldValue('cityRadiusKm', 'unlimited');
                                    } else {
                                        if (lastEnabledCityRadiusKm) {
                                            setFieldValue('cityRadiusKm', lastEnabledCityRadiusKm);
                                        }
                                        if (lastEnabledOrderByKey) {
                                            setFieldValue('orderByKey', lastEnabledOrderByKey);
                                        }
                                        if (autoSubmitOnChange) {
                                            // Submit form when select a city
                                            // eslint-disable-next-line @typescript-eslint/no-floating-promises -- no need to wait
                                            submitForm();
                                        }
                                    }
                                }}
                                onInputChange={
                                    autoSubmitOnChange
                                        ? (_value, reason) => {
                                              // Submit form when click on clear button
                                              if (reason === 'clear') {
                                                  // eslint-disable-next-line @typescript-eslint/no-floating-promises -- no need to wait
                                                  submitForm();
                                              }
                                          }
                                        : undefined
                                }
                                onBlur={
                                    autoSubmitOnChange
                                        ? (value) => {
                                              // Submit form when blur and field is empty
                                              if (!value) {
                                                  // eslint-disable-next-line @typescript-eslint/no-floating-promises -- no need to wait
                                                  submitForm();
                                              }
                                          }
                                        : undefined
                                }
                                initialValue={{
                                    id: initialValues.cityId ?? '',
                                    name: initialValues.cityName ?? '',
                                }}
                            />
                            {!searchButtonResponsive ? (
                                <Button
                                    type="submit"
                                    data-target-id="community-search-form-submit"
                                    style={{ borderRadius: '2rem' }}
                                >
                                    {t('common:search')}
                                </Button>
                            ) : (
                                <IconButton
                                    data-target-id="community-search-form-submit"
                                    type="submit"
                                    color="primary"
                                    style={{ width: '3rem', height: '3rem' }}
                                    iconProps={{
                                        name: 'Search',
                                        size: '1.5rem',
                                    }}
                                />
                            )}
                        </CityAndButtonContainer>
                    </InputContainer>
                    {displayExtraFilters ? (
                        <Action data-target-id="community-search-form-filters">
                            <StyledSelect
                                $minWidth="10rem"
                                name="cityRadiusKm"
                                items={radiusItems}
                                data-target-id="cityRadiusKm-select"
                                onChange={async (value) => {
                                    setLastEnabledCityRadiusKm(value);
                                    await replaceQueryParams?.({
                                        cityRadiusKm: value,
                                        ...(value === 'unlimited'
                                            ? { orderByKey: 'distanceCity' as CommunityOrderByKey }
                                            : {}),
                                    });
                                    logEventDynamic(AnalyticsEvent.FilterDistance);
                                }}
                                InputProps={{
                                    startAdornment: <Icon name="Distance" size="1.25rem" color={colours.primary.O87} />,
                                }}
                                disabled={!values.cityId}
                            />
                            <StyledSelect
                                key={`${values.communityEntityTypes}-select-key`}
                                $minWidth="13.75rem"
                                name="communityEntityTypes"
                                items={
                                    values.activityCategoryKey
                                        ? communityEntityTypesItems.map((item) => ({
                                              ...item,
                                              disabled: Boolean(
                                                  item.value &&
                                                      [String(['pro', 'nonPro']), String(['nonPro'])].includes(
                                                          String(item.value),
                                                      ),
                                              ),
                                          }))
                                        : communityEntityTypesItems
                                }
                                data-target-id="communityEntityTypes-select"
                                onChange={async (value) => {
                                    const newOrderByKey =
                                        values.orderByKey &&
                                        ['recommendation', 'averageRating'].includes(values.orderByKey)
                                            ? ('mutualContacts' as CommunityOrderByKey)
                                            : values.orderByKey;
                                    setLastEnabledOrderByKey(newOrderByKey);
                                    await replaceQueryParams?.({
                                        communityEntityTypes: value as string,
                                        orderByKey: newOrderByKey,
                                    });
                                    logEventDynamic(AnalyticsEvent.FilterProfile);
                                }}
                                InputProps={{
                                    startAdornment: <Icon name="Company" size="1.25rem" color={colours.primary.O87} />,
                                }}
                            />
                            <StyledSelect
                                key={`${values.orderByKey}-${values.cityId}-${values.communityEntityTypes}-select-key`}
                                $minWidth="12.5rem"
                                name="orderByKey"
                                items={getOrderByItemsDependingValues(values)}
                                data-target-id="orderBy-select"
                                onChange={async (value) => {
                                    const castValue = value as CommunityOrderByKey;

                                    setLastEnabledOrderByKey(castValue);
                                    await replaceQueryParams?.({
                                        orderByKey: castValue,
                                    });

                                    logEventDynamic(communityOrderByKeysMappedToAnalyticsEvents[castValue]);
                                }}
                                InputProps={{
                                    startAdornment: <Icon name="OrderBy" size="1.25rem" color={colours.primary.O87} />,
                                }}
                            />
                        </Action>
                    ) : null}
                </Form>
            )}
        </Formik>
    );
};

export default CommunitySearchForm;

import type { ElementType } from 'react';
import React from 'react';
import { useField } from 'formik';

import MenuItem from '@mui/material/MenuItem';

import type { Props as TextFieldProps } from 'src/components/ui/TextField';
import TextField from 'src/components/ui/TextField';

export type Item = {
    value?: string | number;
    label: string;
    disabled?: boolean;
};

interface Props extends Omit<TextFieldProps, 'onChange'> {
    items: Item[];
    onChange?: (value: Item['value']) => void;
    hideIcon?: boolean;
}
const CustomSelect: React.FC<Props> = ({ name, label, onChange, items, hideIcon, ...rest }) => {
    const [field, meta, helpers] = useField(name);
    const { setValue } = helpers;

    const handleChange = (value: Item['value']) => {
        if (onChange) {
            onChange(value);
        }
        setValue(value);
    };

    return (
        <TextField
            id={name}
            name={name}
            value={items.find((item) => item.value === field.value) ?? null}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event.target.value)}
            label={label}
            select
            {...rest}
            SelectProps={{
                ...rest.SelectProps,
                IconComponent: hideIcon ? (null as unknown as ElementType<unknown>) : undefined,
            }}
        >
            {items.map((item: Item, index: number) => (
                <MenuItem
                    key={index}
                    data-target="select-item"
                    data-target-id={`select-item-${item.value}`}
                    value={item.value}
                    disabled={item.disabled}
                >
                    {item.label}
                </MenuItem>
            ))}
        </TextField>
    );
};

export default CustomSelect;

import React, { useState } from 'react';
import type { AutocompleteChangeReason, AutocompleteInputChangeReason } from '@mui/material/Autocomplete';
import Autocomplete from '@mui/material/Autocomplete';
import { useField } from 'formik';
import { useDebounce } from 'react-use';

import { useTranslation } from 'next-i18next';

import Icon from 'src/components/ui/Icon';
import type { Props as TextFieldProps } from 'src/components/ui/TextField';
import TextField from 'src/components/ui/TextField';
import Loader from 'src/components/ui/Loader';

import { useSearchCityQuery } from 'src/hooks/__generated__/queries';

import { colours } from 'src/constants/colours';
import autocompleteInputStyles from 'src/constants/autocompleteInputStyles';
import type { City } from 'src/types/__generated__/graphql';
import { styledComponent } from 'src/utils/styled';

const StyledAutocomplete = styledComponent(Autocomplete)`
    ${autocompleteInputStyles}
`;

type Item =
    | {
          id: string;
          name: string;
      }
    | undefined;

export interface Props {
    name: string;
    label?: string;
    helperText?: string;
    placeholder?: string;
    onChange?: (item: Item | undefined, reason: AutocompleteChangeReason) => void;
    onInputChange?: (value: string, reason: AutocompleteInputChangeReason) => void;
    onBlur?: (value: string) => void;
    initialValue?: Item;
    hideErrors?: boolean;
    InputProps?: TextFieldProps['InputProps'];
    InputLabelProps?: TextFieldProps['InputLabelProps'];
    iconColor?: string;
}

const CityPicker: React.FC<Props> = ({
    name,
    label,
    helperText,
    placeholder,
    onChange,
    onInputChange,
    onBlur,
    initialValue,
    hideErrors,
    InputProps,
    InputLabelProps,
    iconColor,
}) => {
    const { t } = useTranslation(['common']);
    const [_field, meta, helpers] = useField(name);

    const { setValue, setTouched } = helpers;
    const [inputValue, setInputValue] = useState<string>('');
    const [item, setItem] = useState<Item | undefined>(initialValue);
    const [debouncedSearch, setDebouncedSearch] = useState<string>('');
    const { loading, data } = useSearchCityQuery({
        variables: {
            search: debouncedSearch,
        },
        skip: debouncedSearch.length < 2,
    });

    useDebounce(
        () => {
            setDebouncedSearch(inputValue);
        },
        500,
        [inputValue],
    );

    const handleCitySelected = (
        _event: React.SyntheticEvent,
        _cityItem: unknown | undefined,
        reason: AutocompleteChangeReason,
    ) => {
        const cityItem = _cityItem as Item;
        if (cityItem) {
            setItem(cityItem);
            setValue(cityItem.id);
            onChange?.(cityItem, reason);
        } else {
            setValue(undefined);
            setItem(undefined);
            onChange?.(undefined, reason);
        }
    };

    const handleInputChange = (_event: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => {
        setInputValue(value);
        onInputChange?.(value, reason);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setTouched(true);
        onBlur?.(e.target.value);
    };

    const renderOption = (_option: unknown) => {
        const option = _option as City;
        return [option.name, option.zipCodes?.length === 1 ? option.zipCodes[0] : undefined, option.countryShort]
            .filter(Boolean)
            .join(', ');
    };

    return (
        <StyledAutocomplete
            id="city-picker"
            loading={loading}
            options={data?.searchCities ?? []}
            filterOptions={(options) => options}
            value={item}
            getOptionLabel={renderOption}
            renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option) => (
                <span
                    {...props}
                    data-target="autocomplete-option"
                    data-target-id={`autocomplete-option-${(option as City).id}`}
                >
                    {renderOption(option)}
                </span>
            )}
            onBlur={handleBlur}
            onChange={handleCitySelected}
            onInputChange={handleInputChange}
            noOptionsText={t('common:general.noResult')}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={`${name}-search`}
                    value={inputValue}
                    label={label}
                    placeholder={placeholder}
                    helperText={helperText}
                    error={meta.touched && meta.error ? meta.error : undefined}
                    hideErrors={hideErrors}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: <Icon color={iconColor ?? colours.lightColor} name="Map" />,
                        endAdornment: (
                            <>
                                {loading ? <Loader color="inherit" size={20} padding={0} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    inputProps={{ ...params.inputProps, ...InputProps?.inputProps }}
                    InputLabelProps={{
                        shrink: Boolean((params.inputProps as { value?: boolean } | undefined)?.value),
                        ...params.InputLabelProps,
                        ...InputLabelProps,
                    }}
                />
            )}
        />
    );
};

export default CityPicker;

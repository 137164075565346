import { useTranslation } from 'next-i18next';

import type { CustomQueryVariablesCommunitySearch } from 'src/components/community/CommunitySearchForm';

import isNumber from 'src/utils/isNumber';
import useLocalStorage from 'src/hooks/useLocalStorage';
import useAppRouter from 'src/hooks/useAppRouter';
import { routePathCommunitySearch } from 'src/constants/router';
import type { Item } from 'src/components/ui/Select';

export type CommunityOrderByKey = 'name' | 'recommendation' | 'averageRating' | 'mutualContacts' | 'distanceCity';

export const defaultCityRadiusKm = 10;
export const defaultCommunityEntityTypes = String(['company', 'pagesJaunesPro']);
export const defaultCommunityOrderBy: Record<string, 'ASC' | 'DESC'> = {
    currentUserContact: 'ASC',
    mutualContacts: 'DESC',
    name: 'ASC',
};
const useCommunityFilters = () => {
    const { t } = useTranslation(['common', 'community']);
    const router = useAppRouter();

    const { item: lastSearch, saveItem: setLastSearch } = useLocalStorage<
        Pick<CustomQueryVariablesCommunitySearch, 'activityCategoryKey' | 'search' | 'cityId' | 'cityName'> | undefined
    >('last-community-search', undefined);

    const getCommunityRadiusItems = () => {
        const radiusValuesCommunitySearch = [3, 10, 30, 50, 'unlimited'];
        const radiusItems = radiusValuesCommunitySearch.map((value) => ({
            label: isNumber(value) ? `${value} km` : t('community:noLimit'),
            value,
        }));

        return radiusItems;
    };

    // ['company', 'pagesJaunesPro'] => Type 1 : Companies (group included, yellowpage included)
    // ['pro'] => Type 2 : professionnals (users)
    // ['nonPro'] => Type 3 : non pro users
    // ['nonPro', 'pro'] => Type 4 : all users (not the company)
    const getCommunityEntityTypesItems = (): Item[] => [
        {
            label: t('community:communityEntityTypes.companies'),
            value: String(['company', 'pagesJaunesPro']),
        },
        {
            label: t('community:communityEntityTypes.proUsers'),
            value: String(['pro']),
        },
        {
            label: t('community:communityEntityTypes.nonProUsers'),
            value: String(['nonPro']),
        },
        {
            label: t('community:communityEntityTypes.allUsers'),
            value: String(['pro', 'nonPro']),
        },
    ];

    // orderBy possible keys :
    //     currentUserContact = current user contacts or current user company first
    //     mutualContacts = company members in current user contacts or number of mutual contacts
    //     distanceCity = depending the company/user CITY GPS values (and not the address)
    //     distance = depending the company address GPS values and the user city GPS values
    //     recommendation = aggregated for companies
    //     name
    //     averageRating
    const getCommunityOrderByItems = (): Item[] => [
        {
            label: t('community:communityOrderByKey.name'),
            value: 'name' as CommunityOrderByKey,
        },
        {
            label: t('community:communityOrderByKey.recommendation'),
            value: 'recommendation' as CommunityOrderByKey,
        },
        {
            label: t('community:communityOrderByKey.mutualContacts'),
            value: 'mutualContacts' as CommunityOrderByKey,
        },
        {
            label: t('community:communityOrderByKey.averageRating'),
            value: 'averageRating' as CommunityOrderByKey,
        },
        {
            label: t('community:communityOrderByKey.distance'),
            value: 'distanceCity' as CommunityOrderByKey,
        },
    ];

    const getCommunityOrderByFromKey = (orderByKey: CommunityOrderByKey) => {
        let orderBy: Record<string, 'ASC' | 'DESC'> = {};
        switch (orderByKey) {
            case 'name':
                orderBy = {
                    name: 'ASC',
                };
                break;
            case 'recommendation':
                orderBy = {
                    recommendation: 'DESC',
                    name: 'ASC',
                };
                break;
            case 'mutualContacts':
                orderBy = {
                    mutualContacts: 'DESC',
                    name: 'ASC',
                };
                break;
            case 'averageRating':
                orderBy = {
                    averageRating: 'DESC',
                    name: 'ASC',
                };
                break;
            case 'distanceCity':
                orderBy = {
                    distanceCity: 'ASC',
                    name: 'ASC',
                };
                break;
            default:
                break;
        }

        return orderBy;
    };

    const compileParamsAndRedirectToCommunityStep2 = async (values: CustomQueryVariablesCommunitySearch) => {
        const params: string[] = [];
        if (values.activityCategoryKey) {
            const [type, id] = String(values.activityCategoryKey).split(':');
            if (type === 'Category') {
                params.push(`categoryId=${id}`);
            }
            if (['Activity', 'CompanyActivity'].includes(type)) {
                params.push(`activityId=${id}&activityType=${type === 'CompanyActivity' ? 'company' : 'user'}`);
            }
        } else if (values.search) {
            params.push(`search=${values.search}`);
        }

        if (values.cityId) {
            params.push(`cityId=${values.cityId}`);
        }

        if (values.cityName) {
            params.push(`cityName=${values.cityName}`);
        }

        setLastSearch({
            activityCategoryKey: values.activityCategoryKey,
            search: values.search,
            cityId: values.cityId,
            cityName: values.cityName,
        });

        if (params.length) {
            await router.push(`${routePathCommunitySearch}?${params.join('&')}`);
        } else {
            await router.push(routePathCommunitySearch);
        }
    };

    return {
        getCommunityRadiusItems,
        getCommunityEntityTypesItems,
        getCommunityOrderByItems,
        getCommunityOrderByFromKey,
        compileParamsAndRedirectToCommunityStep2,
        setLastSearch,
        lastSearch,
    };
};

export default useCommunityFilters;

import camelCase from 'lodash/camelCase';

import { useTranslation } from 'next-i18next';

import type { Item } from 'src/components/ui/Autocomplete';
import { useActivitiesLazyQuery, useActivityCategoriesLazyQuery } from 'src/hooks/__generated__/queries';
import type { ActivitiesQueryVariables } from 'src/types/__generated__/graphql';

export type ActivityItem = Omit<Item, 'category'> & {
    labelKey: string;
    id: string;
    category: string;
    categoryLabelKey?: string;
};
type GetActivitiesParams = ActivitiesQueryVariables & {
    forCommunity?: boolean;
    prefixTranslationKey?: string;
    replaceOtherActivitiesLabel?: boolean;
    displayExtraCompanyActivities?: boolean;
};
export default function useActivities() {
    const { t } = useTranslation(['common', 'community']);
    const [fetchActivities] = useActivitiesLazyQuery({});
    const [fetchCategories] = useActivityCategoriesLazyQuery();

    const getActivities = async ({
        displayOneOtherOnSignUp = false,
        forCommunity = false,
        companyLabel,
        prefixTranslationKey = 'common:activities',
        replaceOtherActivitiesLabel,
        displayExtraCompanyActivities,
    }: GetActivitiesParams) => {
        const { data } = await fetchActivities({
            variables: {
                displayOneOtherOnSignUp,
                companyLabel,
            },
        });
        const activities = data?.activities ?? [];
        let treatedActivities: ActivityItem[] = [];
        if (forCommunity) {
            // grouped categories and sub categories first, then all activities
            // use it like const activities = await getActivities({ forCommunity: true, orderedByCategories: false });
            const { data: activityCategories } = await fetchCategories({
                variables: {
                    includeOther: true,
                },
            });
            const categories = activityCategories?.activityCategories ?? [];

            categories.forEach((category) => {
                treatedActivities.push({
                    id: category.id,
                    value: `Category:${category.id}`,
                    label: t(`community:categories.${camelCase(category.labelKey)}`),
                    labelKey: category.labelKey,
                    category: t('community:categoriesHeader'),
                });
            });

            activities.forEach((activity) => {
                treatedActivities.push({
                    id: activity.id,
                    value: `Activity:${activity.id}`,
                    label: t(`common:activities.${camelCase(activity.labelKey)}`),
                    labelKey: activity.labelKey,
                    category: t('community:professionsAndActivitiesHeader'),
                });

                if (
                    displayExtraCompanyActivities &&
                    ['notary', 'agent', 'agent_madatory'].includes(activity.labelKey)
                ) {
                    // those 3 label keys need to be shown as user activity AND company activity
                    treatedActivities.push({
                        id: activity.id,
                        value: `CompanyActivity:${activity.id}`,
                        label: t(`common:companyActivities.${camelCase(activity.labelKey)}`),
                        labelKey: activity.labelKey,
                        category: t('community:professionsAndActivitiesHeader'),
                    });
                }
            });

            treatedActivities = treatedActivities.sort(
                (a, b) => a.category.localeCompare(b.category) || a.label.localeCompare(b.label),
            );
        } else {
            activities.forEach((activity) => {
                if (activity.activityCategories.length) {
                    activity.activityCategories.forEach((activityCategory) => {
                        if (activityCategory) {
                            treatedActivities.push({
                                id: activity.id,
                                value: activity.id,
                                label: t(`${prefixTranslationKey}.${camelCase(activity.labelKey)}`),
                                category: t(
                                    `community:categories.${camelCase(
                                        activityCategory.parentActivityCategory
                                            ? activityCategory.parentActivityCategory.labelKey
                                            : activityCategory.labelKey,
                                    )}`,
                                ),
                                labelKey: activity.labelKey,
                                categoryLabelKey: activityCategory.parentActivityCategory
                                    ? activityCategory.parentActivityCategory.labelKey
                                    : activityCategory.labelKey,
                            });
                            if (
                                displayExtraCompanyActivities &&
                                ['notary', 'agent', 'agent_madatory'].includes(activity.labelKey)
                            ) {
                                // those 3 label keys need to be shown as user activity AND company activity
                                treatedActivities.push({
                                    id: activity.id,
                                    value: `CompanyActivity:${activity.id}`,
                                    label: t(`common:companyActivities.${camelCase(activity.labelKey)}`),
                                    category: t(
                                        `community:categories.${camelCase(
                                            activityCategory.parentActivityCategory
                                                ? activityCategory.parentActivityCategory.labelKey
                                                : activityCategory.labelKey,
                                        )}`,
                                    ),
                                    labelKey: activity.labelKey,
                                    categoryLabelKey: activityCategory.parentActivityCategory
                                        ? activityCategory.parentActivityCategory.labelKey
                                        : activityCategory.labelKey,
                                });
                            }
                        }
                    });
                }
            });
            treatedActivities = treatedActivities.sort(
                (a, b) => a.category.localeCompare(b.category) || a.label.localeCompare(b.label),
            );
        }

        if (replaceOtherActivitiesLabel) {
            // Replace other activities label by 'Other' and labelKey by 'other' except other_real_estate
            // For example, { labelKey: 'other_influencer', label: 'Other influence professional' } becomes { labelKey: 'other', label: 'Other' }.
            treatedActivities = treatedActivities.map((activity) => {
                if (activity.labelKey.startsWith('other_') && activity.labelKey !== 'other_real_estate') {
                    return {
                        ...activity,
                        lakelKey: 'other',
                        label: t('common:companyActivities.other'),
                    };
                }
                return activity;
            });
        }
        return treatedActivities;
    };
    return getActivities;
}

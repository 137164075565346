/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

export const groupBy = <T>(array: T[], key: keyof T): Record<string, T[]> =>
    array.reduce((rv, x) => {
        ((rv as any)[(x as any)[key]] = (rv as any)[(x as any)[key]] || []).push(x);
        return rv;
    }, {});

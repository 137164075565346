import { useState, useEffect } from 'react';

import type { CustomQueryVariablesCommunitySearch } from 'src/components/community/CommunitySearchForm';
import type { CreateAdFormValues } from 'src/components/create-ad/MultiStepLayout';
import type { ExtendedAdsQueryVariables } from 'src/components/ad/SearchAdsPage';

export type LocalStorageTypes =
    | undefined
    | CreateAdFormValues
    | ExtendedAdsQueryVariables
    | CustomQueryVariablesCommunitySearch;

const useLocalStorage = <T extends LocalStorageTypes>(itemName: string, initialValue: T) => {
    const [item, setItem] = useState<T | undefined>(initialValue);
    useEffect(() => {
        const localStorageItem = localStorage.getItem(itemName);
        let parsedItem: T | undefined;

        if (!localStorageItem || localStorageItem === 'undefined') {
            localStorage.setItem(itemName, JSON.stringify(initialValue));
            parsedItem = initialValue;
        } else {
            parsedItem = JSON.parse(localStorageItem) as T | undefined;
        }
        setItem(parsedItem);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const saveItem = (newItem: T) => {
        const stringifiedItem = JSON.stringify(newItem);
        // don't save if the new value is the default value excepted if the local storage doesn't exist
        if (JSON.stringify(initialValue) !== stringifiedItem || !localStorage.getItem(itemName)) {
            localStorage.setItem(itemName, stringifiedItem);
            setItem(newItem);
        }
    };

    return { item, saveItem };
};

export default useLocalStorage;

import camelCase from 'lodash/camelCase';

import { useTranslation } from 'next-i18next';

import type { Activity, CompanyLabel } from 'src/types/__generated__/graphql';
import capitalizeFirstLetter from 'src/utils/capitalizeFirstLetter';

interface UserForActivity {
    activity?: Pick<Activity, 'labelKey'> | null | undefined;
    customActivity?: string | null;
}

export default function useActivityToDisplay() {
    const { t } = useTranslation(['common']);

    const getActivityToDisplay = (user?: UserForActivity) => {
        let activity = t('common:individual');

        if (user?.customActivity) {
            activity = capitalizeFirstLetter(user.customActivity);
        } else if (user?.activity?.labelKey.includes('custom:')) {
            // for custom activity stored in sendbird
            activity = capitalizeFirstLetter(user.activity.labelKey.substring(7));
        } else if (user?.activity?.labelKey) {
            activity = t(`common:activities.${camelCase(user.activity.labelKey)}`);
        }

        return activity;
    };
    return getActivityToDisplay;
}

export interface CompanyForActivity {
    activities?: Array<Pick<Activity, 'labelKey'>> | null | undefined;
    customActivity?: string | null;
    label?: CompanyLabel | null | undefined;
}

export function useCompanyActivityToDisplay() {
    const { t } = useTranslation(['common', 'company']);

    const getActivityToDisplay = (company?: CompanyForActivity) => {
        let activity = t('common:company');

        if (company?.customActivity) {
            activity = capitalizeFirstLetter(company.customActivity);
        } else if (company?.activities && company.activities.length > 0) {
            const mainActivity = company.activities[0];
            activity = t(`common:companyActivities.${camelCase(String(mainActivity.labelKey))}`);
        }
        if (company?.label === 'group') {
            activity = `${t('company:groupTitle')} - ${activity}`;
        }

        return activity;
    };
    return getActivityToDisplay;
}
